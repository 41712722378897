import mobileMenu from './components/mobileMenu';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    AOS.init({
      once: true,
      duration: 1000,
      disable: 'mobile'
    });
    mobileMenu();
  },
  false
);

var elem = document.querySelector('.support__list--carousel');
var flkty = new Flickity( elem, {
  adaptiveHeight: true,
  wrapAround: true,
  cellAlign: 'center',
  pageDots: false,
  prevNextButtons: false,
});

// if ( matchMedia('screen and (max-width: 767px)').matches ) {
//   options.prevNextButtons = false;
// }