const mobileMenu = () => {
  let header = document.querySelector('.header');
  let hamburger = document.querySelector('.header__hamburger');
  let menu = document.querySelector('.header__menu-list');
  let dropdown = document.querySelector('.dropdown');
  let submenu = document.querySelector('.header__submenu-list');

  hamburger.addEventListener('click', (e) => {
    e.preventDefault();
    hamburger.classList.toggle('close');
    menu.classList.toggle('active');
    document.body.classList.toggle('overflow');
  });

  dropdown.addEventListener('click', (e) => {
    e.preventDefault();
    submenu.classList.toggle('active');
  });

  // if(window.pageYOffset >= 300) {
  //   header.classList.add('fixed');
  // } else {
  //   header.classList.remove('fixed');
  // }

  // window.addEventListener('scroll', (e) => {
  //   e.preventDefault();
  //   if(window.pageYOffset >= 300) {
  //     header.classList.add('fixed');
  //   } else {
  //     header.classList.remove('fixed');
  //   }
  // });
}

export default mobileMenu